import React from "react";
import ThemeToggle from "./ThemeToggle";

function Footer({toggleTheme, theme}) {
    return (
        <footer>
            <p>© 2025 KlikVooruit. Alle rechten voorbehouden.</p>
            <ThemeToggle toggleTheme={toggleTheme} theme={theme}/>
        </footer>
    );
}

export default Footer;