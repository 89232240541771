import React from "react";

function RegionalInfo() {
    // Base64-encoded SVG emblems voor beide provincies
    const antwerpEmblem = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180">
        <rect width="180" height="180" fill="#E30613"/>
        <path d="M90,20 L154,90 L90,160 L26,90 Z" fill="#FFFFFF"/>
        <path d="M90,40 L136,90 L90,140 L44,90 Z" fill="#E30613"/>
    </svg>`;

    const limburgEmblem = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180">
        <rect width="180" height="180" fill="#FFDC00"/>
        <path d="M30,30 H150 V150 H30 Z" fill="#0033A0"/>
        <path d="M50,50 H130 V130 H50 Z" fill="#FFDC00"/>
    </svg>`;

    return (
        <section className="regional-info">
            <h2>Computerles en Digitale Hulp in Limburg en Antwerpen</h2>
            <p>KlikVooruit biedt persoonlijke computerhulp en digitale begeleiding bij u aan huis in de provincies
                Limburg en Antwerpen. Onze ervaren docenten komen naar u toe zodat u kunt leren op uw eigen apparatuur
                in een vertrouwde omgeving.</p>

            <div className="provinces-container">
                <div className="province-block">
                    <div className="province-emblem">
                        <img
                            src={`data:image/svg+xml;base64,${btoa(antwerpEmblem)}`}
                            alt="Provincie Antwerpen"
                            className="province-logo"
                        />
                    </div>
                    <div className="province-info">
                        <h3>Provincie Antwerpen</h3>
                        <p>Computerlessen en digitale ondersteuning in heel de provincie.</p>
                        <div className="province-cities">
                            <span className="city-tag">Antwerpen</span>
                            <span className="city-tag">Mechelen</span>
                            <span className="city-tag">Turnhout</span>
                            <span className="city-tag">Lier</span>
                            <span className="city-tag">Mol</span>
                            <span className="city-tag">En omstreken</span>
                        </div>
                    </div>
                </div>

                <div className="province-block">
                    <div className="province-emblem">
                        <img
                            src={`data:image/svg+xml;base64,${btoa(limburgEmblem)}`}
                            alt="Provincie Limburg"
                            className="province-logo"
                        />
                    </div>
                    <div className="province-info">
                        <h3>Provincie Limburg</h3>
                        <p>Digitale vaardigheden training op maat in heel de provincie.</p>
                        <div className="province-cities">
                            <span className="city-tag">Hasselt</span>
                            <span className="city-tag">Genk</span>
                            <span className="city-tag">Sint-Truiden</span>
                            <span className="city-tag">Tongeren</span>
                            <span className="city-tag">Beringen</span>
                            <span className="city-tag">En omstreken</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="highlighted-city">
                <div className="city-icon">🏙️</div>
                <div className="city-info">
                    <h3>Hasselt</h3>
                    <p>In de hoofdstad van Limburg bieden we extra aandacht en uitgebreide bijlesdiensten.</p>
                </div>
            </div>

            <div className="regional-info-extra">
                <h3>Waarom lokale ondersteuning belangrijk is</h3>
                <p>Door onze focus op Limburg en Antwerpen kunnen we snel bij u ter plaatse zijn en kennen we de lokale
                    context. Onze docenten spreken uw taal, begrijpen de lokale gebruiken en kunnen specifieke tips
                    geven over digitale diensten in uw regio.</p>
                <p>Of u nu in het centrum van Hasselt, een buitenwijk van Antwerpen of een landelijk dorp in Limburg
                    woont - wij komen graag naar u toe!</p>
            </div>
        </section>
    );
}

export default RegionalInfo;