import React from "react";

function ThemeToggle({toggleTheme, theme}) {
    return (
        <div className="theme-toggle">
            <span className="icon sun">☀️</span>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={theme === "dark"}
                    onChange={toggleTheme}
                />
                <span className="slider"></span>
            </label>
            <span className="icon moon">🌙</span>
        </div>
    );
}

export default ThemeToggle;