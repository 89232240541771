import React from "react";

function Header({scrollToDiensten, scrollToContact}) {
    return (
        <header className="hero">
            <div className="hero-content">
                <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="KlikVooruit Logo"
                    className="logo"
                />
                <h1>Welkom bij KlikVooruit</h1>
                <p>
                    Leer essentiële digitale vaardigheden en blijf verbonden met de
                    wereld om je heen. Voor iedereen in Limburg en Antwerpen die klaar is om digitaal sterker te worden!
                </p>
                <div className="button-group">
                    <button className="cta-button" onClick={scrollToDiensten}>
                        Ontdek Onze Diensten
                    </button>
                    <button className="cta-button" onClick={scrollToContact}>
                        Contacteer Ons Nu
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;