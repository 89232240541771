import React from "react";

function WhyUs() {
    return (
        <section className="why-us">
            <h2>Waarom kiezen voor ons?</h2>
            <div className="reasons-grid">
                <div className="reason-card">
                    <h3>🎓 Ervaren jongeren met echte ervaring</h3>
                    <p>
                        Onze jonge trainers hebben een frisse blik en ervaring in het
                        werken met digitale tools en platforms.
                    </p>
                </div>
                <div className="reason-card">
                    <h3>📐 Op maat van de klant</h3>
                    <p>
                        Wij bieden begeleiding volledig afgestemd op uw
                        behoeften en tempo.
                    </p>
                </div>
                <div className="reason-card">
                    <h3>🤝 Persoonlijke begeleiding</h3>
                    <p>
                        Geen vraag is te simpel – wij zorgen voor persoonlijke
                        ondersteuning die u het vertrouwen geeft om zelfstandig te
                        werken.
                    </p>
                </div>
                <div className="reason-card">
                    <h3>💻 Praktijkgerichte aanpak</h3>
                    <p>
                        Leer direct door te doen! Praktische oefeningen helpen u de
                        vaardigheden meteen toe te passen.
                    </p>
                </div>
                <div className="reason-card">
                    <h3>⏱ Flexibele planning</h3>
                    <p>
                        Wij passen ons aan uw agenda aan, zodat leren altijd op uw
                        tijd gebeurt.
                    </p>
                </div>
                <div className="reason-card">
                    <h3>🌍 Verbinding met de moderne wereld</h3>
                    <p>
                        Beheers technologie om verbonden te blijven met familie, vrienden,
                        en de wereld om u heen.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default WhyUs;