import React from "react";
import { Helmet } from "react-helmet-async";

function SEOHelmet() {
    return (
        <Helmet>
            {/* Primary Meta Tags - Updated with stronger keyword optimization */}
            <title>KlikVooruit - Computerles & Digitale Hulp voor Senioren in Limburg en Antwerpen | Aan Huis</title>
            <meta
                name="description"
                content="Persoonlijke computerles aan huis en smartphone hulp voor senioren in Limburg & Antwerpen. Leer in uw eigen tempo werken met computer, tablet, e-mail, WhatsApp, internetbankieren en videobellen. Veilig en vertrouwd."
            />
            <meta
                name="keywords"
                content="computerles, digitale hulp, computerles voor senioren, pc hulp, smartphone cursus, tablet uitleg, online bankieren, whatsapp leren, facebook voor ouderen, email hulp, videobellen leren, internet voor beginners, Limburg, Antwerpen, Hasselt, Genk, Sint-Truiden, Tongeren, Turnhout, Mechelen, digitale vaardigheden, internet veiligheid, computerhulp aan huis"
            />

            {/* Canonical Link */}
            <link rel="canonical" href="https://klikvooruit.be/"/>

            {/* Language and Locale */}
            <meta property="og:locale" content="nl_BE" />
            <meta http-equiv="content-language" content="nl-BE" />
            <html lang="nl" />

            {/* Mobile Optimization */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="format-detection" content="telephone=yes" />

            {/* Open Graph / Facebook - Enhanced with better descriptions */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://klikvooruit.be/"/>
            <meta property="og:title" content="KlikVooruit - Computerles voor Senioren in Limburg en Antwerpen | Op Uw Eigen Tempo"/>
            <meta property="og:description"
                  content="Persoonlijke computerles aan huis en smartphone begeleiding speciaal voor senioren in Limburg en Antwerpen. In uw vertrouwde omgeving leren omgaan met digitale technologie: e-mail, internet, WhatsApp, en meer."/>
            <meta property="og:image" content="https://klikvooruit.be/images/logo.png"/>
            <meta property="og:image:alt" content="KlikVooruit logo - Computerles voor senioren" />
            <meta property="og:site_name" content="KlikVooruit" />

            {/* Twitter Card - Expanded information */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:url" content="https://klikvooruit.be/" />
            <meta name="twitter:title" content="KlikVooruit - Digitale Hulp voor Senioren | Limburg & Antwerpen"/>
            <meta name="twitter:description"
                  content="Persoonlijke digitale hulp aan huis in Limburg en Antwerpen. Leer werken met computer, smartphone en internet op uw eigen tempo in uw vertrouwde omgeving."/>
            <meta name="twitter:image" content="https://klikvooruit.be/images/logo.png"/>
            <meta name="twitter:image:alt" content="KlikVooruit - Computerles aan huis voor senioren" />

            {/* Chrome, Firefox OS and Opera */}
            <meta name="theme-color" content="#5865f2" />

            {/* iOS Safari */}
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
            <meta name="apple-mobile-web-app-title" content="KlikVooruit" />

            {/* Additional Meta Information */}
            <meta name="author" content="KlikVooruit" />
            <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
            <meta name="googlebot" content="index, follow" />

            {/* Uitgebreide structured data - Enhanced with more specific information */}
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    "name": "KlikVooruit",
                    "image": "https://klikvooruit.be/images/logo.png",
                    "url": "https://klikvooruit.be",
                    "telephone": "",
                    "email": "info@klikvooruit.be",
                    "address": {
                        "@type": "PostalAddress",
                        "addressRegion": "Limburg & Antwerpen, België",
                        "addressCountry": "BE"
                    },
                    "description": "Persoonlijke computerles en digitale vaardigheden training voor senioren en beginners in Limburg en Antwerpen. Wij komen bij u aan huis en bieden ondersteuning op maat.",
                    "slogan": "Digitale vaardigheden voor senioren op uw eigen tempo",
                    "priceRange": "€€",
                    "openingHoursSpecification": [
                        {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": ["Saturday"],
                            "opens": "10:00",
                            "closes": "16:00"
                        }
                    ],
                    "areaServed": [
                        {
                            "@type": "AdministrativeArea",
                            "name": "Limburg"
                        },
                        {
                            "@type": "AdministrativeArea",
                            "name": "Antwerpen"
                        }
                    ],
                    "serviceArea": {
                        "@type": "GeoCircle",
                        "geoMidpoint": {
                            "@type": "GeoCoordinates",
                            "latitude": "51.0259",
                            "longitude": "5.3130"
                        },
                        "geoRadius": "80000"
                    },
                    "hasOfferCatalog": {
                        "@type": "OfferCatalog",
                        "name": "Computerlessen en digitale diensten",
                        "itemListElement": [
                            {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Computerles aan huis"
                                }
                            },
                            {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Smartphone begeleiding"
                                }
                            },
                            {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Tablet training"
                                }
                            },
                            {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Internet veiligheid"
                                }
                            }
                        ]
                    },
                    "sameAs": [
                        "https://www.facebook.com/klikvooruit",
                        "https://www.instagram.com/klikvooruit"
                    ],
                    "keywords": ["computerles", "digitale hulp", "senioren", "aan huis", "Limburg", "Antwerpen"]
                }
            `}</script>

            {/* Service Structured Data - Enhanced with more details */}
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "Service",
                    "serviceType": "Computerles voor Senioren",
                    "name": "Persoonlijke computerles aan huis",
                    "provider": {
                        "@type": "LocalBusiness",
                        "name": "KlikVooruit",
                        "url": "https://klikvooruit.be"
                    },
                    "areaServed": [
                        {
                            "@type": "City",
                            "name": "Hasselt"
                        },
                        {
                            "@type": "City",
                            "name": "Genk"
                        },
                        {
                            "@type": "City",
                            "name": "Sint-Truiden"
                        },
                        {
                            "@type": "City",
                            "name": "Tongeren"
                        },
                        {
                            "@type": "City",
                            "name": "Antwerpen"
                        },
                        {
                            "@type": "City",
                            "name": "Mechelen"
                        },
                        {
                            "@type": "City",
                            "name": "Turnhout"
                        }
                    ],
                    "description": "Persoonlijke computerles en smartphone training aan huis voor senioren, in een vertrouwd tempo en op uw eigen apparaten. Wij helpen u met e-mail, internet, WhatsApp, videobellen en meer.",
                    "audience": {
                        "@type": "Audience",
                        "audienceType": "Senioren",
                        "geographicArea": {
                            "@type": "AdministrativeArea",
                            "name": ["Limburg", "Antwerpen"]
                        }
                    },
                    "offers": {
                        "@type": "Offer",
                        "availability": "https://schema.org/InStock",
                        "areaServed": ["Limburg", "Antwerpen"],
                        "deliveryMethod": "OnSiteDelivery",
                        "price": "",
                        "priceCurrency": "EUR",
                        "priceSpecification": {
                            "@type": "PriceSpecification",
                            "description": "Prijzen op aanvraag, afhankelijk van uw specifieke wensen"
                        }
                    },
                    "serviceOutput": {
                        "@type": "Thing",
                        "description": "Verbeterde digitale vaardigheden, zelfstandig gebruik van computer, smartphone, tablet en online diensten"
                    }
                }
            `}</script>

            {/* FAQ Schema - Expanded with more questions and enhanced answers */}
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                        {
                            "@type": "Question",
                            "name": "In welke regio's komt KlikVooruit aan huis?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "KlikVooruit biedt computerles en digitale ondersteuning aan huis in de provincies Limburg en Antwerpen. We komen in alle gemeenten waaronder Hasselt, Genk, Sint-Truiden, Tongeren, Antwerpen, Mechelen, Turnhout en omliggende gebieden. Onze diensten zijn beschikbaar voor alle inwoners van deze provincies, met speciale aandacht voor de hoofdsteden en grotere gemeenten."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Welke hulp biedt KlikVooruit voor senioren?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "KlikVooruit biedt persoonlijke computerles en digitale ondersteuning voor senioren, waaronder hulp bij e-mail, internetbankieren, sociale media (Facebook, Instagram), videobellen met familie (Zoom, WhatsApp, FaceTime), online winkelen, het gebruik van smartphones en tablets (iPhone, iPad, Android), tekstverwerking, fotobewerking en -opslag, en veilig internetgebruik. Alle ondersteuning wordt op uw eigen tempo en niveau aangeboden."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Heb ik voorkennis nodig voor de computerles?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Nee, geen enkele voorkennis is nodig. Onze lessen zijn juist bedoeld voor beginners en worden volledig aangepast aan uw niveau en leertempo. We starten vanaf de basis en bouwen geleidelijk uw vaardigheden op. Zelfs als u nog nooit een computer of smartphone heeft gebruikt, kunnen we u stap voor stap leren hoe u deze apparaten veilig en effectief kunt gebruiken."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Kan ik ook leren hoe ik veilig online bankier?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Absoluut. We leggen stap voor stap uit hoe u veilig kunt internetbankieren, inclusief het herkennen van phishing en andere online gevaren. We helpen u met het instellen van uw bank-app, het beveiligen van uw accounts met sterke wachtwoorden, het uitvoeren van overschrijvingen, het raadplegen van uw saldo en het instellen van automatische betalingen. Veiligheid staat hierbij altijd voorop."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Wat zijn de kosten voor computerles aan huis?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "De kosten zijn afhankelijk van uw specifieke wensen, behoeften en de regio waarin u woont. We werken met eerlijke, transparante tarieven zonder verborgen kosten. Neem contact met ons op via het contactformulier en we maken graag een vrijblijvende offerte op maat. We bieden zowel losse lessen als voordelige pakketten aan voor wie regelmatig begeleiding wenst."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Hoe lang duurt een computerles bij KlikVooruit?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Een standaard les duurt ongeveer 1,5 tot 2 uur. Dit is lang genoeg om substantiële vooruitgang te boeken, maar kort genoeg om vermoeidheid te voorkomen. We passen de lesduur aan op basis van uw behoeften en concentratievermogen. Na elke les krijgt u duidelijke aantekeningen mee, zodat u het geleerde later nog eens rustig kunt doornemen."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Moet ik zelf een computer of tablet hebben voor de lessen?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Ja, het is het meest effectief als we werken op uw eigen apparatuur. Zo leert u direct met uw eigen computer, tablet of smartphone te werken in uw vertrouwde omgeving. Heeft u nog geen apparaat? Dan kunnen we u adviseren bij de aanschaf van een geschikt toestel dat bij uw behoeften past, en u helpen met de eerste installatie en configuratie."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Kan ik ook leren hoe ik foto's van mijn smartphone op mijn computer krijg?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Zeker! We leren u graag hoe u foto's van uw smartphone naar uw computer kunt overzetten, hoe u deze kunt organiseren, bewerken en veilig kunt bewaren. Ook het maken van back-ups, het delen van foto's met familie en vrienden via e-mail of sociale media, en het afdrukken van foto's komen aan bod."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Helpt KlikVooruit ook bij het installeren van software en apps?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Ja, we helpen u graag bij het vinden, installeren en gebruiken van nuttige software en apps. Of het nu gaat om antivirusprogramma's, communicatie-apps zoals WhatsApp of Zoom, of praktische toepassingen zoals agenda's of notitie-apps - we zorgen ervoor dat alles correct wordt geïnstalleerd en dat u leert hoe u deze programma's veilig en effectief kunt gebruiken."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Hoe maak ik een afspraak voor computerles aan huis?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Een afspraak maken is eenvoudig. U kunt contact met ons opnemen via het contactformulier op onze website of telefonisch. We bespreken dan uw wensen en behoeften en plannen een moment dat u goed uitkomt. Onze flexibele planning betekent dat we ook in de avonduren of op zaterdag beschikbaar zijn."
                            }
                        }
                    ]
                }
            `}</script>

            {/* Breadcrumbs Schema - Enhanced with more structured approach */}
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": 1,
                            "name": "Home",
                            "item": "https://klikvooruit.be/"
                        },
                        {
                            "@type": "ListItem",
                            "position": 2,
                            "name": "Diensten",
                            "item": "https://klikvooruit.be/#diensten"
                        },
                        {
                            "@type": "ListItem",
                            "position": 3,
                            "name": "Regio's",
                            "item": "https://klikvooruit.be/#regios"
                        },
                        {
                            "@type": "ListItem",
                            "position": 4,
                            "name": "Contact",
                            "item": "https://klikvooruit.be/#contact"
                        }
                    ]
                }
            `}</script>

            {/* Organization Schema */}
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "KlikVooruit",
                    "url": "https://klikvooruit.be",
                    "logo": "https://klikvooruit.be/images/logo.png",
                    "sameAs": [
                        "https://www.facebook.com/klikvooruit",
                        "https://www.instagram.com/klikvooruit"
                    ],
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "contactType": "customer service",
                        "email": "info@klikvooruit.be",
                        "availableLanguage": ["Dutch"]
                    }
                }
            `}</script>

            {/* Website Schema */}
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "name": "KlikVooruit",
                    "url": "https://klikvooruit.be",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://klikvooruit.be/search?q={search_term_string}",
                        "query-input": "required name=search_term_string"
                    }
                }
            `}</script>

            {/* City Services Schema */}
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "Service",
                    "name": "Computerles aan huis in Hasselt",
                    "serviceType": "Digitale Ondersteuning",
                    "provider": {
                        "@type": "LocalBusiness",
                        "name": "KlikVooruit"
                    },
                    "areaServed": {
                        "@type": "City",
                        "name": "Hasselt"
                    },
                    "description": "Persoonlijke computerles en digitale ondersteuning aan huis in Hasselt. Voor senioren en beginners."
                }
            `}</script>

            {/* Alternatieve spellingsvormen keywords - Expanded and improved */}
            <meta name="keywords"
                  content="komputer les, kompjoeter hulp, pc kursus, komputer cursus, smartphone les, gsm uitleg, online bankirren, watsapp leren, digitaale vaardigheeden, internet leren, seniooren computerles, tablett hulp, digihulp, pc-les, laptop training, email hulp, internet opleiding, computer hulp, PC begeleiding, tablet kursus, digibeet hulp, ouderen internet, kompjoeter moeilijkheden, beginners cursus, elderly computer training, WhatsApp uitleg, computer voor ouderen"/>

            {/* Technical SEO enhancements */}
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <meta name="referrer" content="no-referrer-when-downgrade" />
            <meta name="google" content="nositelinkssearchbox" />
            <meta name="google-site-verification" content="your-verification-code" />

            {/* Mobile App association */}
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="manifest" href="/site.webmanifest" />
        </Helmet>
    );
}

export default SEOHelmet;