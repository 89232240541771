import React, {useState} from "react";
import * as emailjs from "emailjs-com";

function ContactForm({contactRef, setShowPopup}) {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        reason: "",
        source: "",
        contactMethod: "",
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Kaart veldnamen naar gebruiksvriendelijke labels
    const fieldLabels = {
        firstName: "Voornaam",
        lastName: "Achternaam",
        email: "E-mail",
        phone: "Telefoonnummer",
        reason: "Dit veld",
        source: "Bron",
        contactMethod: "Contactmethode"
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});

        // Clear the error message as the user types
        setErrors({...errors, [name]: ""});
    };

    const handleBlur = (e) => {
        const {name, value} = e.target;
        const label = fieldLabels[name] || name;

        // Validate on blur
        if (!value.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: `${label} is verplicht.`,
            }));
        } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Ongeldig e-mailadres.",
            }));
        } else if (
            name === "phone" &&
            !/^(?:\+32|0032|0{1,2}32|0)?\s?(4\d{2})\s?\d{2}\s?\d{2}\s?\d{2}$/.test(
                value.replace(/\s/g, "")
            )
        ) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: "Ongeldig telefoonnummer.",
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        Object.entries(formData).forEach(([key, value]) => {
            if (!value.trim() && key !== "source" && key !== "contactMethod") {
                const label = fieldLabels[key] || key;
                newErrors[key] = `${label} is verplicht.`;
            }
        });

        if (!formData.source) {
            newErrors.source = "Selecteer a.u.b. hoe u ons heeft gevonden.";
        }

        if (!formData.contactMethod) {
            newErrors.contactMethod = "Selecteer a.u.b. hoe we u mogen contacteren.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsSubmitting(true);

        emailjs
            .send(
                "klikvooruit-p001",
                "klikvooruit-p001-temp",
                formData,
                "zYVX1HnJt1nYOMEfD"
            )
            .then(() => {
                setShowPopup();

                // Reset het formulier
                setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    reason: "",
                    source: "",
                    contactMethod: "",
                });
                setErrors({});
                setIsSubmitting(false);
            })
            .catch((error) => {
                setIsSubmitting(false);
                alert("Er is een fout opgetreden bij het verzenden. Probeer het opnieuw.");
                console.error("Fout bij verzenden:", error);
            });
    };

    // Verbeterde radio button stijl
    const RadioOption = ({id, name, value, label, checked, onChange}) => (
        <div className="radio-option">
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                checked={checked}
                disabled={isSubmitting}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );

    return (
        <section className="contact" ref={contactRef}>
            <h2>Neem contact op</h2>
            <p>Heeft u vragen of wilt u lessen boeken? Vul het onderstaande formulier in.</p>
            <p>We nemen zo snel als mogelijk contact met u op.</p>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Voornaam</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Uw voornaam"
                        disabled={isSubmitting}
                    />
                    {errors.firstName && (
                        <p className="error-message">{errors.firstName}</p>
                    )}
                </div>
                <div className="form-group">
                    <label>Achternaam</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Uw achternaam"
                        disabled={isSubmitting}
                    />
                    {errors.lastName && (
                        <p className="error-message">{errors.lastName}</p>
                    )}
                </div>
                <div className="form-group">
                    <label>E-mail</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Uw e-mailadres"
                        disabled={isSubmitting}
                    />
                    {errors.email && (
                        <p className="error-message">{errors.email}</p>
                    )}
                </div>
                <div className="form-group">
                    <label>Telefoonnummer</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Uw telefoonnummer"
                        disabled={isSubmitting}
                    />
                    {errors.phone && (
                        <p className="error-message">{errors.phone}</p>
                    )}
                </div>
                <div className="form-group form-group-radio">
                    <div className="radio-title">Hoe wilt u gecontacteerd worden?</div>
                    <div className="radio-options">
                        <RadioOption
                            id="contact-email"
                            name="contactMethod"
                            value="email"
                            label="E-mail"
                            checked={formData.contactMethod === "email"}
                            onChange={handleChange}
                        />
                        <RadioOption
                            id="contact-phone"
                            name="contactMethod"
                            value="phone"
                            label="Telefoon"
                            checked={formData.contactMethod === "phone"}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.contactMethod && (
                        <p className="error-message">{errors.contactMethod}</p>
                    )}
                </div>

                <div className="form-group">
                    <label>Hoe kunnen we u van dienst zijn?</label>
                    <textarea
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Wat zou u willen leren of graag hulp bij hebben?"
                        disabled={isSubmitting}
                    ></textarea>
                    {errors.reason && (
                        <p className="error-message">{errors.reason}</p>
                    )}
                </div>
                <div className="form-group form-group-radio">
                    <div className="radio-title">Hoe heeft u ons gevonden?</div>
                    <div className="radio-options">
                        <RadioOption
                            id="source-google"
                            name="source"
                            value="google"
                            label="Google"
                            checked={formData.source === "google"}
                            onChange={handleChange}
                        />
                        <RadioOption
                            id="source-vrienden"
                            name="source"
                            value="vrienden"
                            label="Via vrienden"
                            checked={formData.source === "vrienden"}
                            onChange={handleChange}
                        />
                        <RadioOption
                            id="source-socialmedia"
                            name="source"
                            value="socialmedia"
                            label="Sociale media"
                            checked={formData.source === "socialmedia"}
                            onChange={handleChange}
                        />
                        <RadioOption
                            id="source-advertentie"
                            name="source"
                            value="advertentie"
                            label="Advertentie"
                            checked={formData.source === "advertentie"}
                            onChange={handleChange}
                        />
                        <RadioOption
                            id="source-anders"
                            name="source"
                            value="anders"
                            label="Anders"
                            checked={formData.source === "anders"}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.source && (
                        <p className="error-message">{errors.source}</p>
                    )}
                </div>

                <button
                    type="submit"
                    className={`cta-button ${isSubmitting ? 'submitting' : ''}`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Verzenden..." : "Verstuur"}
                </button>
            </form>
        </section>
    );
}

export default ContactForm;