import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import Header from "./components/Header";
import Services from "./components/Services";
import WhyUs from "./components/WhyUs";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import RegionalInfo from "./components/RegionalInfo";
import SEOHelmet from "./components/SEOHelmet";

function App() {
    const [showPopup, setShowPopup] = useState(false);
    const [popupTimer, setPopupTimer] = useState(10);
    const [theme, setTheme] = useState("dark");
    const dienstenRef = useRef(null);
    const contactRef = useRef(null);
    const topRef = useRef(null);
    const timerIntervalRef = useRef(null);

    // Apply the theme to the body element
    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    // Detect system theme on initial load and listen for changes
    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        const applySystemTheme = () => setTheme(mediaQuery.matches ? "dark" : "light");

        applySystemTheme(); // Apply initial theme
        mediaQuery.addEventListener("change", applySystemTheme); // Listen for changes

        return () => {
            mediaQuery.removeEventListener("change", applySystemTheme); // Clean up on unmount
        };
    }, []);

    // Countdown timer for popup
    useEffect(() => {
        if (showPopup && popupTimer > 0) {
            timerIntervalRef.current = setInterval(() => {
                setPopupTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (popupTimer === 0) {
            closePopup();
        }

        return () => {
            if (timerIntervalRef.current) {
                clearInterval(timerIntervalRef.current);
            }
        };
    }, [showPopup, popupTimer]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
    };

    const closePopup = () => {
        setShowPopup(false);
        setPopupTimer(10);
        if (timerIntervalRef.current) {
            clearInterval(timerIntervalRef.current);
        }
    };

    const handleFormSubmit = () => {
        setShowPopup(true);
        setPopupTimer(10);
    };

    const scrollToDiensten = () => dienstenRef.current.scrollIntoView({behavior: "smooth"});
    const scrollToContact = () => contactRef.current.scrollIntoView({behavior: "smooth"});

    return (
        <div className="container">
            <SEOHelmet/>
            <div ref={topRef}></div>

            {showPopup && (
                <div className="corner-popup">
                    <div className="popup-content">
                        <div className="popup-header">
                            <div className="timer-container">
                                <svg viewBox="0 0 36 36" className="timer-svg">
                                    <circle
                                        cx="18"
                                        cy="18"
                                        r="16"
                                        fill="none"
                                        className="timer-bg"
                                        strokeWidth="2"
                                    />
                                    <circle
                                        cx="18"
                                        cy="18"
                                        r="16"
                                        fill="none"
                                        className="timer-progress"
                                        strokeWidth="3"
                                        strokeDasharray="100"
                                        strokeDashoffset={100 - ((10 - popupTimer) / 10) * 100}
                                    />
                                    <text
                                        x="18"
                                        y="22"
                                        textAnchor="middle"
                                        className="timer-text"
                                    >
                                        {popupTimer}
                                    </text>
                                </svg>
                            </div>
                            <button className="popup-close-btn" onClick={closePopup}>×</button>
                        </div>
                        <div className="popup-icon-success">✓</div>
                        <h3>Bedankt!</h3>
                        <p>Uw aanvraag is succesvol verzonden.</p>
                        <p className="popup-detail">We nemen binnen 24 uur contact met u op.</p>
                    </div>
                </div>
            )}

            <Header
                scrollToDiensten={scrollToDiensten}
                scrollToContact={scrollToContact}
            />

            <main>
                <Services
                    dienstenRef={dienstenRef}
                    scrollToContact={scrollToContact}
                />

                <div className="graphic-container">
                    <img src="/images/image.png" alt="Digitale vaardigheden illustratie" className="graphic-image"/>
                </div>

                <WhyUs/>

                <RegionalInfo/>

                <ContactForm
                    contactRef={contactRef}
                    setShowPopup={handleFormSubmit}
                />
            </main>

            <Footer toggleTheme={toggleTheme} theme={theme}/>
        </div>
    );
}

export default App;