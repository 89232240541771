import React from "react";

function Services({dienstenRef, scrollToContact}) {
    return (
        <section className="services" ref={dienstenRef}>
            <h2>Onze Diensten voor Digitale Zelfstandigheid</h2>
            <br/>
            <div className="service-grid">
                <div className="service-card">
                    <h3>🖥️ Computer- en Tabletlessen</h3>
                    <p>Leer stap voor stap werken met uw computer, laptop of tablet. Van aanzetten tot bestanden beheren
                        - alles wordt duidelijk uitgelegd.</p>
                    <ul className="service-list">
                        <li>Windows of Mac gebruiken</li>
                        <li>Bestanden organiseren</li>
                        <li>Tekstverwerking (Word)</li>
                        <li>Internet gebruiken</li>
                    </ul>
                </div>
                <div className="service-card">
                    <h3>📱 Smartphone Begeleiding</h3>
                    <p>Maak optimaal gebruik van uw smartphone met persoonlijke uitleg op maat van uw toestel en uw
                        behoeften.</p>
                    <ul className="service-list">
                        <li>Basis instellingen</li>
                        <li>Apps installeren en gebruiken</li>
                        <li>Foto's maken en beheren</li>
                        <li>Google Maps gebruiken</li>
                    </ul>
                </div>
                <div className="service-card">
                    <h3>💬 Communicatie Tools</h3>
                    <p>Blijf eenvoudig in contact met familie en vrienden via moderne communicatiemiddelen.</p>
                    <ul className="service-list">
                        <li>WhatsApp gebruiken</li>
                        <li>Videobellen met Zoom/Skype</li>
                        <li>E-mail opstellen en versturen</li>
                        <li>Facebook en andere sociale media</li>
                    </ul>
                </div>
                <div className="service-card">
                    <h3>🔒 Online Veiligheid</h3>
                    <p>Leer hoe u veilig kunt internetten en uw persoonlijke gegevens kunt beschermen.</p>
                    <ul className="service-list">
                        <li>Sterke wachtwoorden maken</li>
                        <li>Phishing herkennen</li>
                        <li>Veilig online winkelen</li>
                        <li>Privacy-instellingen beheren</li>
                    </ul>
                </div>
            </div>

            <div className="service-card" style={{maxWidth: "600px", margin: "30px auto 0"}}>
                <h3>Persoonlijke Begeleiding op Maat</h3>
                <p>Al onze lessen worden gegeven op uw eigen tempo en niveau. We komen naar u toe in Limburg en
                    Antwerpen en gebruiken uw eigen apparatuur, zodat u direct kunt toepassen wat u leert in uw
                    vertrouwde omgeving.</p>
            </div>
        </section>
    );
}

export default Services;